@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'klamp';
  src: url('../assets/fonts/klamp-105-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'klamp';
  src: url('../assets/fonts/klamp-105-medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PPRadioGrotesk';
  src: url('../assets/fonts/PPRadioGrotesk-Black.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PPRadioGrotesk';
  src: url('../assets/fonts/PPRadioGrotesk-Regular.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SpaceMono';
  src: url('../assets/fonts/SpaceMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SpaceMono';
  src: url('../assets/fonts/SpaceMono-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


.klamp-font {
  font-family: klamp, Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.PPRadioGrotesk-font {
  font-family: klamp, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.spaceMono-font {
  font-family: SpaceMono, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.inter-regular-font {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.inter-semibold-font {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.inter-bold-font {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: bold;
}
  