@import "src/styles/mixins";
@import "src/styles/colors";

.bottom-navigation-container {
  height: 90px;
  width: 100%;
  background-color: $medium-grey;
  padding: 24px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: "fixed";
  bottom: 0;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease;
    width: 25%;

    &:active {
      transform: scale(0.75);
    }

    svg {
      stroke: white !important;
    }

    .label {
      font-size: 11px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      color: white;
      text-transform: capitalize;
      margin-top: 8px;
      margin-bottom: 0px;
    }

    &.selected {
      font-weight: 600 !important;
      color: $red !important;

      svg {
        stroke: $red !important;
      }

      .label {
        color: $red !important;
      }
    }

    &.disabled {
      color: $neutral !important;

      svg {
        stroke: $neutral !important;
      }

      .label {
        color: $neutral !important;
      }
    }
  }
}
