$black: #3d3c3d;
$grey: #73888c;
$medium-grey: #373d43;
$light-grey: #e8e3da;
$dark-grey: #2c2f33;
$primary: #f7931a;
$red: #f74b37;
$green: #66c61c;
$neutral: #7c726f;
$border-second: #cabebb;
