@import "src/styles/mixins";
@import "src/styles/colors";

.blocked-screen-container {
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }

  .content {
    position: relative;
    z-index: 2;
    top: 20%;
    width: 100%;
    padding: 0px 24px;
    text-align: center;

    .hero-text {
      @include klamp-bold-font;
      font-size: 48px;
      font-weight: 700;
      color: white;

      &.isMobile {
        font-size: 40px !important;
      }

      span {
        color: $red !important;

        &.new-line {
          display: block;
        }
      }
    }

    .connect-wallet-button {
      height: 46px !important;
      width: 200px;
      font-size: 16px !important;
      z-index: 2;
    }
  }
}
